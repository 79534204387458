<template>
	<MhRouterView class="DevView view" :isContentReady="isContentReady">

		<RbAppHeader
			:textRight="_.get( app, 'acfOptions.headerTextRight' )"
		></RbAppHeader>

		<RbAppMenu></RbAppMenu>

		<div class="DevView__inner view__inner hSpace hSpace--app">

			<br/>

			<mark>Auflistung der Form-Elemente</mark><br/>
			<BaseCheckbox
				:value="true"
				:disabled="false"
				@change="(e)=>{}"
				@input="(value)=>{}"
			></BaseCheckbox> <span class="color--white">M</span>
			<BaseCheckbox
				:value="false"
				:disabled="false"
				@change="(e)=>{}"
				@input="(value)=>{}"
			></BaseCheckbox>
			<hr/>
			<BaseRadio
				:name="'test'"
				:checkedValue="'heiko'"
				:value="'invoice'"
				@input="(e)=>{}"
			></BaseRadio> <span class="color--white">M</span>
			<BaseRadio
				:name="'test'"
				:checkedValue="'mario'"
				:value="''"
				@input="(e)=>{}"
			></BaseRadio>
			<input type="radio" name="test" value="heiko" /><span class="testSpan">???</span>
			<input type="radio" name="test" value="mario" /><span class="testSpan">???</span>
			<hr/>
			<div class="flex">
				<BaseButton class="RbCartTable__button"
					:isOutlined="true"
					@click.native="$router.push('/checkout/customer/')">
					<template slot="before">
						<MhIcon type="arrow-left"></MhIcon>
					</template>
					<template slot="default">Zurück</template>
					<template slot="after">
					</template>
				</BaseButton>
				<BaseButton class="RbCartTable__button"
					@click.native="$router.push('/checkout/thank-you/')">
					<template slot="before"></template>
					<template slot="default">Kostenpflichtig bestellen</template>
					<template slot="after">
						<MhIcon type="arrow-right"></MhIcon>
					</template>
				</BaseButton>
			</div>
			<div>
				<hr/>
				<BaseText
					:value="'Mario'"
					:placeholder="''"
					:required="false"
					:disabled="false"
					:hasClearButton="true"
					:debounce="true"
					:debounceDelay="250"
					@input="(e)=>{}"
				></BaseText>
				<hr/>
				<BaseText
					:value="''"
					:placeholder="'My placeholder'"
					:required="false"
					:disabled="false"
					:hasClearButton="true"
					:debounce="true"
					:debounceDelay="250"
					@input="(e)=>{}"
				></BaseText>
				<hr/>
				<BaseTextarea
					:value="'Mario'"
					:placeholder="''"
					:required="false"
					:disabled="false"
					:resizeable="'vertical'"
					:debounce="true"
					:debounceDelay="250"
					@change="(e)=>{}"
					@input="(e)=>{}"
				></BaseTextarea>
			</div>
			<hr/>

			<mark>Auflistung aller Font-Classes</mark>
			<div class="DevView__fontExample font font--serif font--sizeBig" label="font--serif font--sizeBig">
				Serif-Headline 60/70 <br/>
				font--serif font--sizeBig
			</div>
			<div class="DevView__fontExample font font--sans font--sizeBig" label="font--sans font--sizeBig">
				Großer Sans-Fliesstext 58/70<br/>
				font--sans font--sizeBig
			</div>
			<div class="DevView__fontExample font font--sans font--sizeDefault" label="font--sans font--sizeDefault">
				Normaler Sans-Fliesstext, 26/36<br/>
				font--sans font--sizeDefault
			</div>
			<div class="DevView__fontExample font font--serif font--sizeDefault" label="font--serif font--sizeDefault">
				Header Serif-Fliesstext, 25/34<br/>
				font--serif font--sizeDefault
			</div>
			<div class="DevView__fontExample font font--sans font--sizeMedium font--uppercase" label="font--sans font--sizeMedium font--uppercase">
				Versalien 30/30 <br/>
				font--sans font--sizeMedium font--uppercase
			</div>
			<div class="DevView__fontExample font font--sans font--sizeSmall" label="font--sans font--sizeSmall">
				Kleiner Sans-Fliesstext, 18/26<br/>
				font--sans font--sizeSmall
			</div>

			<!--
			<div class="font font--serif">
				font--serif
			</div>
			-->

		</div>

		<br/>
		<mark>Auflistung der Inhaltsmodule</mark>

		<RbNewsRow
			:title="'Es geht wieder los!'"
			:text="'Rabindranath Tagores Reden sind über 100 Jahre alt, aber heute leider wieder sehr relevant. Das Buch Nationalismus verhandelt die Gier und den Rassismus sich hinter Grenzen verschanzender Nationen, deren oberste Maxime der Eigennutz ggü. vermeintlich minderwertigen Völkern ist.'"
			:date="'2021-06-21T09:30:12'"
		></RbNewsRow>
		<RbNewsRow
			:title="'Noch ein Titel'"
			:text="'Das Buch Nationalismus verhandelt die Gier und den Rassismus sich hinter Grenzen verschanzender Nationen, deren oberste Maxime der Eigennutz ggü. vermeintlich minderwertigen Völkern ist.'"
			:date="'2021-04-08T12:06:19'"
		></RbNewsRow>

		<RbSectionTitleRow
			:title="'Events22'"
		></RbSectionTitleRow>
		<RbEventHeader
			:endDate="'20210622'"
			:endTime="'1800'"
			:title="'Lesung: Terror, Traum und Zeiterfahrungen im Holocaust'"
		></RbEventHeader>
		<RbEventHeader
			:endDate="'20211020'"
			:endTime="'2130'"
			:title="'Marios Geburtstag'"
		></RbEventHeader>

		<MhDevPanel></MhDevPanel>

	</MhRouterView>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'
	import MhDevPanel from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevPanel/v1/MhDevPanel.vue'

	import RbAppHeader from "@/components/RbAppHeader.vue"
	import RbAppMenu from "@/components/RbAppMenu.vue"
	import RbNewsRow from '@/components/RbNewsRow.vue'
	import RbEventHeader from '@/components/RbEventHeader.vue'
	import RbSectionTitleRow from '@/components/RbSectionTitleRow.vue'
	import RbAppFooter from "@/components/RbAppFooter.vue"

	import BaseRadio from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseRadio.vue'
	import BaseText from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseText.vue'
	import BaseTextarea from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseTextarea.vue'
	import BaseCheckbox from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseCheckbox.vue'
	import BaseButton from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseButton.vue'
	import MhIcon from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhIcon/v2/MhIcon.vue'

	export default {
		name: 'DevView',
		components: {
			MhRouterView,
			MhDevPanel,
			RbAppHeader,
			RbAppMenu,
			RbEventHeader,
			RbSectionTitleRow,
			RbAppFooter,
			RbNewsRow,
			BaseRadio,
			BaseText,
			BaseTextarea,
			BaseCheckbox,
			BaseButton,
			MhIcon,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				isContentReady: false,
				pages: [],
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		mounted(){
			this.isContentReady = true
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	input:checked + .testSpan { color: green; }

	.DevView {
		&__inner {
			h2 {
				//font-size: 32px;
				//line-height: 1.2em;
				font-weight: bold;
				padding-bottom: 0.5em;
			}

			.DevView__fontExample {
				position: relative;
				padding: 0.5rem;
				//padding-bottom: 0.5rem;
				outline: 1px solid fade( red, 50 );
				background-color: fade( red, 5 );

				&::before {
					position: absolute;
					top: 0; right: 0;
					content: attr(label);
					font-size: 14px;
					line-height: 1em;
					text-transform: none;
					background-color: fade( red, 50 );
					padding: 0.25em;
					font-family: Arial, sans-serif;
					color: white;
				}
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
